import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import financeManageService from '@/service/financeManage';
import styles from './finance.module.less';

const FinanceInvoice = (props) => {
    console.log(props, '2222222222222222');
    const { requestVal, moduleItem } = props;
    const [dataSource, setDataSource] = useState([]);
    const columns = [
        {
            title: '开票类型',
            dataIndex: 'typeTxt',
            align: 'right',
        },
        {
            title: '发票号',
            dataIndex: 'invoiceNum',
            align: 'right',
        },
        {
            title: '发票收取状态',
            dataIndex: 'status',
            align: 'right',
        },
        {
            title: '开票日期',
            dataIndex: 'billDate',
            align: 'right',
        },
        {
            title: '总金额',
            dataIndex: 'amount',
            align: 'right',
        },
        {
            title: '税额',
            dataIndex: 'tax',
            align: 'right',
        },
    ];

    const getQueryInvoiceFiles = () => {
        let params = {
            miceId: requestVal.miceId,
            supplierId: requestVal.supplierId,
        };
        financeManageService.QueryFields(params).then((res) => {
            console.log(res, 'res');
            if (res && res.success) {
                setDataSource(res.content);
            }
        });
    };
    useEffect(() => {
        getQueryInvoiceFiles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <div className={styles.title}>{moduleItem.title}</div>
            <Table columns={columns} dataSource={dataSource} bordered />
        </div>
    );
};

export default FinanceInvoice;
