import React, { useEffect, useState, useRef } from "react";
import SmartQueryReact from "smart-iquery-react";
import SubTable from "./SubTable";
import commonService from "@/service/common";
import { message, Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import Xform from "@/components/Xform/xformRender";
import XformContext from "@/components/Xform/xformContext";
import QueryModal from "@/pages/HomePage/PageModal/QueryModal";
const { confirm } = Modal;
export default function IqueryTable(props) {
  const queryModalRef = useRef();
  console.log(props, "props==============s")
  const [handleMethodData, setHandleMethodData] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [queryModalVisible, setQueryModalVisible] = useState(false);
  const [queryModalData, setQueryModalData] = useState([]);
  const [queryModalBtnData, setQueryModalBtnData] = useState({});
  const [selectQueryModalData, setSelectQueryModalData] = useState([]);
  //xform状态数据
  const [XformData, setXformData] = useState(null);
  const xFormModalRef = useRef();
  useEffect(() => {
    if (XformData) {
      xFormModalRef.current.handelFormAction({
        actionType: "openModal",
      })
    }
  }, [XformData]);

  const reloadData = () => {
    const rnd = new Date().getTime();
    console.log(rnd, "--------rnd")
    setHandleMethodData(rnd);
  };
  // 行数据选中回调
  const returnSelectedRows = (rowsId, rows) => {
    console.log(rowsId);
    setSelectedRowKeys(rowsId);
    setSelectedRows(rows);
  };
  const confirmMethod = (btn, rowKeys) => {
    confirm({
      title: btn.text || btn.actionName,
      icon: <ExclamationCircleFilled />,
      mask: true,
      maskClosable: true,
      content: (
        <div>
          <p>确定{btn.text || btn.actionName}吗？</p>
        </div>
      ),
      okText: "确定",
      cancelText: "取消",
      onOk() {
        let parameters = btn.parameters;
        for (let key in parameters.body) {
          console.log(key);
          console.log(rowKeys);
          if (key === "invoiceIdList") {
            parameters.body[key] = rowKeys;
          }
        }
        commonHandle(parameters.body, btn);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const commonHandle = async (params, btn) => {
    let parameters = btn.parameters;
    let url = parameters.api;
    let method = "post";
    let res = await commonService.common(method, url, params);
    if (res && res.success) {
      message.success(res.msg || "操作成功");
      if (btn.actionCode === "btn-export") {
        window.open(res.content.url)
      } else {
        reloadData();
      }
    }
  };
  /**
*
* @param {object} btn 按钮信息
* @param {object} record 行数据
*/
  const applyXform = (btn, record) => {
    console.log(btn, props, selectedRows, "-------------props.record")
    setXformData({
      modalWidth: btn?.modalWidth,
      formRef: btn.actionCode,
      record: btn.checked ? selectedRows : props.getQueryData.rowData,
      btnData: btn.parameters
    });
  };
  const showQueryModal = (btn, record) => {
    setQueryModalBtnData(btn);
    let obj = {
      queryId: btn.Request?.queryId || record?.queryId,
      queryId4Count: btn.Request?.queryId4Count || record?.queryId4Count,
      cluster: btn.Request?.cluster || record?.cluster || "default",
    };
    let params = {};
    let schema = btn.schema && JSON.parse(JSON.stringify(btn.schema));
    for (let key in schema) {
      if (Array.isArray(record)) {
        let arr1 = [];
        record.map((obj) => arr1.push(obj[key]));
        params["_" + key] = JSON.stringify(arr1);
      } else {
        params["_" + key] = record[key] ? record[key] : schema[key];
      }
    }
    obj.additional = params;
    console.log(params);
    let arr = [];
    arr.push(obj);
    setSelectQueryModalData(record);
    setQueryModalData(arr);
    setQueryModalVisible(true);
  };
  const queryModalVisibleCallback = (flag) => {
    setQueryModalVisible(false);
    // flag && reloadData();
  };
  const headerBtnHandleClick = (btn, item, items) => {
    if (btn.actionType) {
      switch (btn.actionType) {
        case "xform":
          if (
            btn.checked &&
            selectedRows.length < 1
          ) {
            message.info("请先勾选一条数据");
            return;
          }
          console.log("====当前行信息作为上下文注入Xform环境====", item);
          applyXform(btn, item);
          break;
        default:
          return;
      }
      return
    }
    let data = item ? item : btn;
    switch (data.actionCode) {
      case "confirmMethod":
        confirmMethod(data, selectedRowKeys);
        break;
      case "genericQueryModal":
        showQueryModal(data, selectedRows.length > 0 ? selectedRows : props.getQueryData.rowData);
        break;
      default:
        console.log("headerBtnHandleClick", data);
        // 创建一个自定义事件
        const customEvent = new CustomEvent('myCustomEvent', {
          detail: { data: data, time: new Date() }
        });

        // 触发自定义事件
        window.dispatchEvent(customEvent);
        return;
        
    }
  }
  const rowMenuHandleClick = (e, record, item) => {
    headerBtnHandleClick(item, record);
  }
  return (
    props.getQueryData && (
      <>
        <XformContext.Provider value={{ XformData, reloadData }}> <div style={props.style}>
          <Xform
            info={selectedRows[0]}
            XformData={XformData}
            reloadData={reloadData}
            ref={xFormModalRef}
          ></Xform>
          {queryModalVisible && (
            <QueryModal
              ref={queryModalRef}
              rowMenuHandleClick={() => { }}
              selectQueryModalData={selectQueryModalData}
              queryModalBtnData={queryModalBtnData}
              queryModalData={queryModalData}
              headerBtnHandleClick={headerBtnHandleClick}
              queryModalVisibleCallback={queryModalVisibleCallback}
              queryModalVisible={queryModalVisible}
            ></QueryModal>
          )}
          <SmartQueryReact getQueryData={props.getQueryData.getQueryData}
            gatewayPath={props.gatewayPath ||
              process.env.REACT_APP_GATEWAY}
            iSignalr={props.iSignalr ||
              process.env.REACT_APP_SIGNALER}
            {...props.getQueryData.expandable && {
              expandable: {
                expandedRowRender: (record) => (
                  <SubTable
                    expandable={props.getQueryData.expandable}
                    record={record}
                    info={props.info}
                  />
                ),
                rowExpandable: (record) => props.getQueryData.expandable,
              }
            }
            }


            goDetail={() => { }}
            handleMethod={handleMethodData}
            getQueryObject={() => { }}
            isModalTable={props.getQueryData.isModalTable}
            rowMenuHandleClick={rowMenuHandleClick}
            returnSelectedRows={returnSelectedRows}
            headerBtnHandleClick={headerBtnHandleClick}
          ></SmartQueryReact>
        </div>
        </XformContext.Provider>
      </>
    )
  );
}