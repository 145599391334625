import React, { useState, useEffect } from 'react';
import commonService from '@/service/common';
import financeManageService from '@/service/financeManage';
import { Button, message } from 'antd';
import { useGeneric } from '@/controllers/useGeneric';
import styles from './channel.module.less';
import { SmartStorage } from '@/utils';

const Footer = (props) => {
    const { requestVal } = props;
    const { replaceEvalObject } = useGeneric();
    const [btns, setBtns] = useState(null);
    const getAction = () => {
        // let params = {
        //     QueryId: '56a15cfa-0ebe-4eac-b2a7-7b39b6422684',
        //     cluster: 'biz',
        //     parameters: {
        //         _taskId: requestVal?.taskId,
        //     },
        // };
        // commonService.getQueryData(params).then((res) => {
        //     if (res.success && res.content?.rows?.length > 0) {
        //         getButtons(res.content.rows[0]?.taskId);
        //     } else {
        //         message.error('未查询到任务信息');
        //     }
        // });
        let params = {
            roleCodes: SmartStorage.get('role_codes'),
            taskStatus: requestVal.taskStatus,
        };
        financeManageService.GetAction(params).then((res) => {
            if (res.success && res.content) {
                console.log(JSON.parse(res.content), 'res');
                setBtns(JSON.parse(res.content));
            }
        });
    };
    // const getButtons = (id) => {
    //     let params = {
    //         taskId: id,
    //     };
    //     commonService.queryTaskActions(params).then((res) => {
    //         if (res && res.success) {
    //             setBtns(res.content);
    //         }
    //     });
    // };
    const handleClick = (item) => {
        let extData = replaceEvalObject(item.extData, requestVal);
        commonService
            .common(extData.methodType, extData.url, extData.param)
            .then((res) => {
                if (res.success) {
                    message.success('操作成功');
                    props.iqueryDetailDrawerCallback({ type: 'ok' });
                }
            });
    };
    useEffect(() => {
        getAction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {btns && (
                <div className={styles.btns}>
                    {btns?.map((item, index) => {
                        return (
                            <Button
                                key={index}
                                type="primary"
                                className={styles.btnItem}
                                onClick={() => handleClick(item)}
                            >
                                {item.actName}
                            </Button>
                        );
                    })}
                </div>
            )}
        </>
    );
};

export default Footer;
