import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Form, Input, Button, Upload, Select, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { getCookie } from 'tiny-cookie';
import commonService from '@/service/common';
import financeManageService from '@/service/financeManage';
import AddMeeting from '../AddMeeting/AddMeeting';
import _ from 'lodash';
const { TextArea } = Input;
const { Option } = Select;
const UpdateCost = (props) => {
    const { costModalVisible, drawerData } = props;
    const [fileList, setFileList] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [supplierOptions, setSupplierOptions] = useState([]);
    const [feeTypeOptions, setFeeTypeOptions] = useState([]);
    const [activeCategory, setActiveCategory] = useState(null);
    const [activeSupplier, setActiveSupplier] = useState(null);
    const [fieldsValue, setFieldsValue] = useState(null);
    // 会议列表相关联的会议id
    const [relationMiceIds, setRelationMiceIds] = useState('');
    const [meetingModalVisible, setMeetingModalVisible] = useState(false);
    const [form] = Form.useForm();
    const handleCostCancel = () => {
        props.handleCostCancel('cancel');
    };
    const addMeeting = () => {
        let fieldsValue = form.getFieldsValue();
        setFieldsValue(fieldsValue);
        // console.log(fieldsValue, 'fieldsValue');
        setMeetingModalVisible(true);
    };
    const queryCategory = () => {
        let params = {
            QueryId: '17cbe2d6-2cdf-4518-a907-d576cff1b1c6',
            cluster: 'biz',
            parameters: {},
        };
        commonService.getQueryData(params).then((res) => {
            if (res.success) {
                setCategoryOptions(res.content.rows);
                if (drawerData?.category) {
                    let activeData = res.content.rows.find(
                        (item) => item.value === drawerData?.category,
                    );
                    setActiveCategory(activeData);
                }
            }
        });
    };
    const querySupplier = (keyWord) => {
        let params = {
            QueryId: '40e0aa94-c803-4b0d-9a86-81d65a6d547d',
            cluster: 'biz',
            parameters: {
                _name: keyWord ? keyWord : '?',
            },
        };
        commonService.getQueryData(params).then((res) => {
            if (res.success) {
                setSupplierOptions(res.content.rows);
                if (drawerData?.supplierId) {
                    let activeData = res.content.rows.find(
                        (item) => item.value === drawerData?.supplierId,
                    );
                    setActiveSupplier(activeData);
                }
            }
        });
    };
    const queryFeeTypes = () => {
        let params = {
            QueryId: '5a172ad1-18f2-4010-8e47-98ee119c03e8',
            cluster: 'biz',
            parameters: {},
        };
        commonService.getQueryData(params).then((res) => {
            if (res.success) {
                setFeeTypeOptions(res.content.rows);
            }
        });
    };
    const queryOssFiles = () => {
        let params = {
            QueryId: '273973fd-14c3-484c-9322-8634dcb16e78',
            cluster: 'biz',
            parameters: {
                _RelatedId: drawerData?.taskId,
            },
        };
        // 查询oss文件
        commonService.getQueryData(params).then((res) => {
            if (res.success) {
                let files = res.content.rows.map((item, index) => {
                    item.uid = index;
                    item.name = item.fileName;
                    item.status = 'done';
                    return item;
                });
                setFileList(files);
            }
        });
    };
    const handleChange = (file) => {
        setFileList(file.fileList);
        if (file.file.status === 'done') {
            mappingFile(file);
        }
    };
    const mappingFile = (files) => {
        let parmas = {
            fileKeys: [files.file.response.content.fileKey],
            mappingData: {
                catalog: '渠道费管理',
                itemId: drawerData?.itemId,
                relatedId: drawerData?.taskId,
                processId: drawerData?.processId,
                tenantId: drawerData?.tenantId,
            },
        };
        commonService.mapping(parmas).then((res) => {
            if (res.success) {
                queryOssFiles();
            }
        });
    };
    const handleRemove = (file) => {
        let params = {
            FileKeys: [file.fileKey],
        };
        commonService.deleteFiles(params).then((res) => {
            if (res.success) {
                queryOssFiles();
            }
        });
    };
    const handleCategory = (value) => {
        let activeData = categoryOptions.find((item) => item.value === value);
        setActiveCategory(activeData);
    };
    const handleSupplier = (value) => {
        let activeData = supplierOptions.find((item) => item.value === value);
        setActiveSupplier(activeData);
    };
    const handleMeetingCancle = (data) => {
        setRelationMiceIds(data?.activeMiceIds || '');
        form.setFieldsValue({
            ...drawerData,
            ...fieldsValue,
            ...{ settlementAmount: data?.amount.toFixed(2) + '' },
        });
        setMeetingModalVisible(false);
    };
    const handleCostOk = () => {
        let cloneFormData = null;
        form.validateFields().then((values) => {
            cloneFormData = structuredClone(values);
            let params = {
                ...cloneFormData,
                ...{
                    id: drawerData?.taskId || '',
                    batchNumber: drawerData?.batchNumber || '',
                    supplierName: activeSupplier?.label,
                    miceIds:
                        relationMiceIds === ''
                            ? drawerData?.miceIds || ''
                            : relationMiceIds,
                },
            };
            financeManageService.RebateTask(params).then((res) => {
                if (res.success) {
                    props.handleCostCancel('ok');
                }
            });
        });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceFunc = useCallback(
        _.debounce((value) => querySupplier(value), 800),
        [],
    );
    const handleSupplierSearch = (value) => {
        value && debounceFunc(value);
    };

    const handleFeeRatio = () => {
        let fieldsValue = form.getFieldsValue();
        if (!fieldsValue.settlementAmount) {
            message.error('请先输入结算金额');
            return;
        }
        fieldsValue.receivableAmount =
            parseFloat(fieldsValue.settlementAmount) *
                (parseFloat(fieldsValue.feeRatio) / 100).toFixed(2) +
            '';
        fieldsValue.expectedFeeAmount = fieldsValue.receivableAmount; // 预计渠道费金额
        form.setFieldsValue(fieldsValue);
    };

    useEffect(() => {
        let cloneData = structuredClone(drawerData);
        form.setFieldsValue(cloneData);
        queryOssFiles();
        queryCategory();
        querySupplier(null);
        queryFeeTypes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Modal
                title="新增/修改渠道费"
                destroyOnClose
                maskClosable={false}
                open={costModalVisible}
                width={600}
                onCancel={() => handleCostCancel('cancel')}
                onOk={handleCostOk}
            >
                <Form
                    form={form}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    labelAlign="right"
                >
                    <Form.Item
                        label="选择品类"
                        name="category"
                        rules={[
                            {
                                required: true,
                                message: '请选择品类',
                            },
                        ]}
                    >
                        <Select
                            onChange={handleCategory}
                            disabled={drawerData?.taskId ? true : false}
                            options={categoryOptions}
                        />
                    </Form.Item>
                    <Form.Item
                        label="选择供应商"
                        name="supplierId"
                        rules={[
                            {
                                required: true,
                                message: '选择供应商',
                            },
                        ]}
                    >
                        <Select
                            disabled={drawerData?.taskId ? true : false}
                            onChange={handleSupplier}
                            options={supplierOptions}
                            showSearch
                            filterOption={false}
                            onSearch={(e) => handleSupplierSearch(e)}
                        >
                            {supplierOptions.length > 0 &&
                                supplierOptions.map((option) => (
                                    <Option
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {option.label}
                                    </Option>
                                ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="选择渠道类型"
                        name="feeType"
                        rules={[
                            {
                                required: true,
                                message: '选择渠道类型',
                            },
                        ]}
                    >
                        <Select
                            disabled={drawerData?.taskId ? true : false}
                            options={feeTypeOptions}
                        />
                    </Form.Item>
                    <Form.Item
                        label="选择会议"
                        name="feeType"
                        rules={[
                            {
                                required: false,
                                message: '选择会议',
                            },
                        ]}
                    >
                        <Button
                            disabled={
                                drawerData.taskType === '自动生成'
                                    ? true
                                    : false
                            }
                            onClick={addMeeting}
                        >
                            查看/添加会议
                        </Button>
                    </Form.Item>
                    <Form.Item
                        label="结算总金额"
                        name="settlementAmount"
                        rules={[
                            {
                                required: true,
                                message: '请输入结算总金额',
                            },
                        ]}
                    >
                        <Input disabled={true} />
                    </Form.Item>
                    <Form.Item
                        label="渠道费比例(%)"
                        name="feeRatio"
                        rules={[
                            {
                                required: true,
                                message: '请输入渠道比例',
                            },
                        ]}
                    >
                        <Input onChange={handleFeeRatio} />
                    </Form.Item>
                    <Form.Item
                        label="预计渠道费金额"
                        name="expectedFeeAmount"
                        rules={[
                            {
                                required: true,
                                message: '请输入预计渠道费金额',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="应收金额"
                        name="receivableAmount"
                        rules={[
                            {
                                required: true,
                                message: '请输入应收金额金额',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="备注"
                        name="remarks"
                        rules={[
                            {
                                required: false,
                                message: '请输入备注',
                            },
                        ]}
                    >
                        <TextArea />
                    </Form.Item>
                    {drawerData?.taskId && (
                        <Form.Item
                            label="支持文件"
                            rules={[
                                {
                                    required: false,
                                    message: '请上传支持文件',
                                },
                            ]}
                        >
                            <Upload
                                action={`${process.env.REACT_APP_GATEWAY}/foundation/aliyun/api/oss/upload`}
                                accept="*"
                                type="list"
                                showUploadList={{
                                    showDownloadIcon: true,
                                    showPreviewIcon: true,
                                    showRemoveIcon: true,
                                }}
                                onChange={handleChange}
                                onRemove={handleRemove}
                                maxCount={1}
                                fileList={fileList}
                                headers={{
                                    Authorization: `Bearer${getCookie(
                                        'token',
                                    )}`,
                                    SqlInjectionMiddleware: false,
                                }}
                            >
                                <Button
                                    type="primary"
                                    icon={<UploadOutlined />}
                                >
                                    导入
                                </Button>
                            </Upload>
                        </Form.Item>
                    )}
                </Form>
            </Modal>
            {meetingModalVisible && (
                <AddMeeting
                    meetingModalVisible={meetingModalVisible}
                    drawerData={drawerData}
                    fieldsValue={fieldsValue}
                    handleMeetingCancle={handleMeetingCancle}
                    activeData={{ activeCategory, activeSupplier }}
                ></AddMeeting>
            )}
        </>
    );
};

export default UpdateCost;
